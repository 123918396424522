import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { MdMenu } from "react-icons/md";
import { menuItems } from "../../../constants/mainLinks";
import Btn from "../../Botones/Btn";

const MainNavbar = () => {
  // STATES
  const [showLinks, setShowLinks] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);

  // MAPPING NAV LINKS
  const navLinks = menuItems.map((link) => {
    return (
      <Link
        to={link.path}
        key={link.text}
        className={
          showLinks
            ? "mx-3 text-xl py-4 transition font-buttonFont block border-b-2 border-b-light-gray"
            : "mx-3 text-base"
        }
        aria-hidden="true"
      >
        {link.text}
      </Link>
    );
  });

  // SHADOW TO STICKY MENU
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setHasScrolled(true);
      } else {
        setHasScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Add Tailwind CSS classes to your menu based on the hasScrolled state
  const menuClasses = `sticky top-0 w-full transition-all ease-in-out duration-300 z-50 full-w ${
    hasScrolled ? "sm:shadow-s1 shadow-s2 sm:py-0 mb-10 bg-white" : "sm:py-5"
  }`;

  return (
    <section className={menuClasses}>
      <header className="sm:flex sm:items-center sm:flex-row sm:justify-between sm:max-w-default px-4 sm:px-0">
        <div className="flex items-center flex-row justify-between">
          <Link to="/">
            <StaticImage
              src="../../../assets/images/Logo/Logo.png"
              alt="Logo marca personal Eduardo Velázquez"
              width={120}
            />
          </Link>
          <MdMenu
            className="block sm:hidden"
            onClick={() => setShowLinks(!showLinks)}
            style={{ width: "25px", height: "25px" }}
            alt="Menú"
          />
        </div>
        <nav
          className={
            showLinks ? "block sm:flex w-full pt-10 pb-4" : "hidden sm:block"
          }
        >
          {navLinks}
          <Btn
            className="my-4 sm:my-0"
            cta="Contáctame"
            url="/contacto"
            full="false"
          />
        </nav>
      </header>
    </section>
  );
};

export default MainNavbar;
