import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";

const MainFooter = () => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          defaultTitle
        }
      }
    }
  `);

  return (
    <footer className="full-w pt-16 bg-gray-f8">
      <p className="text-center text-sm text-dark-gray">
        <Link className="text-sm text-dark-gray" to="/aviso-legal">
          Aviso legal{" "}
        </Link>
        | &copy; {data.site.siteMetadata.defaultTitle}{" "}
        {new Date().getFullYear()}. Todos los derechos reservados.
      </p>
    </footer>
  );
};

export default MainFooter;
