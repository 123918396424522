import React from 'react'
import MainLayout from '../../components/Layouts/MainLayout'
import { SEOComponent } from '../../components/SEO/SEOComponent'

const Privacidad = () => {  
  return (
    <MainLayout>            
      <section className="flex-row page__width--s pad__tops">
        <div className="col-1">
          <h1 className="center">Política de Privacidad</h1>
          <h2>Responsable</h2>
          <p>
            El Responsable del tratamiento de los datos recabados por medio de
            este Sitio Web es:
          </p>
          <p>
            ZUME, S.R.L.; Mauricio Báez #267b, 10154, Distrito Nacional,
            República Dominicana; hola@zume.do.
          </p>

          <h2>De la política de privacidad</h2>

          <p>
            La presente Política de Privacidad establece los términos en
            que&nbsp;Zume&nbsp;usa y protege la información que es proporcionada
            por sus usuarios al momento de utilizar su sitio web. Esta compañía
            está comprometida con la seguridad de los datos de sus usuarios.
            Cuando le pedimos llenar los campos de información personal con la
            cual usted pueda ser identificado, lo hacemos asegurando que sólo se
            empleará de acuerdo con los términos de este documento. Sin embargo,
            esta Política de Privacidad puede cambiar con el tiempo o ser
            actualizada por lo que le recomendamos y enfatizamos revisar
            continuamente esta página para asegurarse que está de acuerdo con
            dichos cambios.
          </p>

          <h2>Información que es recogida</h2>

          <p>
            Nuestro sitio web podrá recoger información personal por ejemplo:
            Nombre, información de contacto como su dirección de correo
            electrónica e información demográfica. Así mismo, cuando sea
            necesario, podrá ser requerida información específica para procesar
            algún pedido o realizar una entrega o facturación.
          </p>

          <h2>Uso y finalidad de la información recogida</h2>

          <p>
            Nuestro sitio web emplea la información con el fin de proporcionar
            el mejor servicio posible, particularmente para mantener un registro
            de usuarios, de pedidos en caso que aplique, y mejorar nuestros
            productos y servicios. Es posible que sean enviados correos
            electrónicos periódicamente a través de nuestro sitio con ofertas
            especiales, nuevos productos y otra información publicitaria que
            consideremos relevante para usted o que pueda brindarle algún
            beneficio, estos correos electrónicos serán enviados a la dirección
            que usted proporcione y podrán ser cancelados en cualquier momento.
          </p>

          <p>
            Zume está altamente comprometido para cumplir con el compromiso de
            mantener su información segura. Usamos los sistemas más avanzados y
            los actualizamos constantemente para asegurarnos que no exista
            ningún acceso no autorizado.
          </p>

          <p>
            Los datos personales del usuario de este sitio web se tratarán para
            las siguientes finalidades:
          </p>
          <ul>
            <li>
              a) Atender las solicitudes de información y/o consultas efectuadas
              por el Usuario. Los datos tratados con esta finalidad se
              conservarán hasta haber dado respuesta a la solicitud de
              información y/o consulta.
            </li>

            <li>
              b) Mantener informado al Usuario, incluso por medios electrónicos,
              acerca de los productos, servicios y novedades de&nbsp;Zume. Los
              datos tratados con esta finalidad se conservarán hasta el momento
              en que el Usuario retire su consentimiento dado para la recepción
              de dichas comunicaciones. Si el Usuario no consiente el
              tratamiento de sus datos con esta finalidad, deberá marcar la
              casilla habilitada a tal efecto en el Sitio Web.
            </li>

            <li>
              c) En caso de utilizar la sección “trabaja con nosotros”, los
              datos facilitados por el Usuario se tratarán para atender su
              solicitud de empleo y, en su caso, permitirle optar a un puesto de
              trabajo en&nbsp;Zume. &nbsp;Los datos tratados con esta finalidad
              se conservarán hasta que el Usuario revoque su consentimiento.
            </li>

            <li>
              d) En caso de introducir datos personales en el Chatbot
              implementado en este sitio web, serán tratados para proporcionarle
              la información o contenidos solicitados. Los datos tratados con
              esta finalidad se conservarán hasta haber dado respuesta a sus
              peticiones.
            </li>

            <li>
              e) Posibilitar la participación del Usuario en las actividades
              propuestas en el Sitio Web, así como la descarga de contenidos
              gratuitos ofrecidos en el mismo. En este
              caso,&nbsp;Zume&nbsp;también utilizará los datos del Usuario para
              mantenerle informado, incluso por medios electrónicos, sobre los
              productos, servicios y novedades de&nbsp;Zume, así como los de
              terceras empresas exclusivamente pertenecientes a los sectores de
              actividad de textil, moda, ocio y espectáculos, deportes,
              alimentación y bebidas, estética y cuidado personal,
              telecomunicaciones, financiero, seguros, viajes, inmobiliario,
              construcción, formación, gran consumo, automoción, seguridad,
              energía, agua y ONGs.
            </li>
          </ul>
          <p>
            De igual modo, Zume podrá comunicar los datos personales del Usuario
            a terceras empresas pertenecientes a los sectores de actividad antes
            indicados, con el fin de que éstas puedan remitirle comunicaciones
            comerciales, incluso por medios electrónicos, relativas a sus
            productos, servicios y novedades, de así ser solicitado por parte
            del Usuario y/o ser requerido dentro de uno de los proyectos
            contratados por el mismo. Los datos tratados con esta finalidad se
            conservarán hasta que el Usuario revoque su consentimiento.
          </p>

          <p>
            El usuario sabe y acepta que la participación en las actividades
            propuestas y/o la descarga de los contenidos gratuitos ofrecidos en
            el sitio web están obligatoriamente condicionadas a la plena
            aceptación inicial de toda la finalidad antes indicada. En caso de
            no aceptar tal condición, el Usuario debe abstenerse de participar
            en las actividades propuestas y/o descargar los contenidos gratuitos
            ofrecidos en el Sitio Web.
          </p>

          <h2>Enlaces a Terceros</h2>

          <p>
            Este sitio web pudiera contener en laces a otros sitios que pudieran
            ser de su interés. Una vez que usted de clic en estos enlaces y
            abandone nuestra página, ya no tenemos control sobre al sitio al que
            es redirigido y por lo tanto no somos responsables de los términos o
            privacidad ni de la protección de sus datos en esos otros sitios
            terceros. Dichos sitios están sujetos a sus propias políticas de
            privacidad por lo cual es recomendable que los consulte para
            confirmar que usted está de acuerdo con estas.
          </p>

          <h2>Control de su información personal</h2>

          <p>
            En cualquier momento usted puede restringir la recopilación o el uso
            de la información personal que es proporcionada a nuestro sitio web.
            Cada vez que se le solicite rellenar un formulario, como el de alta
            de usuario, puede marcar o desmarcar la opción de recibir
            información por correo electrónico. En caso de que haya marcado la
            opción de recibir nuestro boletín o publicidad usted puede
            cancelarla en cualquier momento.
          </p>

          <p>
            Esta compañía no venderá, cederá ni distribuirá la información
            personal que es recopilada sin su consentimiento, salvo que sea
            requerido por un juez con un orden judicial.
          </p>

          <p>
            Zume se reserva el derecho de cambiar los términos de la presente
            Política de Privacidad en cualquier momento.
          </p>
        </div>
      </section>
    </MainLayout>
  )
}

export default Privacidad

export const Head = () => (
  <SEOComponent
    title="Política de privacidad"
    type="Page"
    featuredImage=""
    robots="noindex, nofollow"
    description="Política de privacidad del sitio web www.zume.do"
    isCanonical={false}
    canonicalUrl={null}
    dateCreated={null}
    keywords=""
  />
);
