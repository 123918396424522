import React from "react";
import { Link } from "gatsby";

const Btn = ({ url, cta, full }) => {
  let filterFull;
  if (full === "true") {
    filterFull = "btn w-full";
  } else {
    filterFull = "btn";
  }

  return (
    <Link to={url}>
      <button className={filterFull}>{cta}</button>
    </Link>
  );
};

export default Btn;
