import React from "react";
import MainNavbar from "./Navs/MainNavbar";
import MainFooter from "./Footers/MainFooter";

const MainLayout = ({ children }) => {
  return (
    <>
      <MainNavbar />
      <main className="sm:w-default min-h-3/4 sm:px-0 px-5">{children}</main>
      <MainFooter />
    </>
  );
};

export default MainLayout;
